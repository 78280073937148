import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';

export enum CommercePropertyValueEnum {
    string = 'StringValue',
    decimal = 'DecimalValue',
    boolean = 'BooleanValue'
}

export default function getExtensionPropertyValueByKey(
    properties: CommerceProperty[] | undefined,
    key: string,
    valueType: CommercePropertyValueEnum
) {
    const property = properties?.find(p => p.Key === key)?.Value;
    // eslint-disable-next-line security/detect-object-injection
    const val = property ? property[valueType] : null;
    return val;
}
